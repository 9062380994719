import React, { useRef, useState, useEffect } from "react"
import useOutsideClick from "../../../hooks/useOutsideClick"
import { Modal, Button, Form } from "react-bootstrap";
import { doRegister } from "../../../services/account-services"
import { setUser, getUser, handleLogin, isLoggedIn } from "../../../services/auth"
import PreferencesModal from "./PreferencesModal"
import config from "../../../config/config"
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

const RegisterModal = () => {
    const [showRegister, setShowRegister] = useState(true);    
    const handleCloseRegister = () => setShowRegister(false);
    const handleShowRegister = () => setShowRegister(true);
    
    const [showPreferences, setShowPreferences] = useState(false);
    const handleClosePreferences = () => setShowPreferences(false);
    const handleShowPreferences = () => setShowPreferences(true);
    
  
    const onRegisterFormSubmit = (event) => {
        event.preventDefault();
        let userdata = {
            Email: event.target.elements.Email.value,
            FirstName: event.target.elements.FirstName.value,
            Surname: event.target.elements.Surname.value,
            Password: event.target.elements.Password.value,
            Phone: event.target.elements.Phone.value,
            Remember: event.target.elements.Remember.value,
        };
        doRegister(userdata);
        
        setTimeout(function(){
            let user = getUser();
            let valid_user = Object.keys(user).length > 0;
            if(valid_user){
                handleCloseRegister();
                handleShowPreferences();
            }
        },700);
  };
  return (
    <>
    <Modal
      className="modalContainer"
      show={showRegister}
      animation={true}
      size={'lg'}
      onHide={handleCloseRegister}
    >
      <div className="modal-content-inner">
        <div className="btn-close d-block d-lg-none" onClick={handleCloseRegister}></div>
        <RegisterForm onSubmit={event => {onRegisterFormSubmit(event)}} />
      </div>
    </Modal>    
    <PreferencesModal show={showPreferences} />
    </>
  )
}

const RegisterForm = ({ onSubmit }) => {
        const responseFacebook = (response) => {
            if(typeof response === 'object'){
                let _data = {
                    email: response.email,
                    name: response.name,
                    fbid: response.id,
                    accessToken: response.accessToken
                };
                handleLogin(_data);
            }
        }
        const responseGoogle = (response) => {
            if(typeof response === 'object'){
                let google_profile = response.profileObj
                if(typeof google_profile === 'object'){
                    let _data = {
                        email: google_profile.email,
                        name: google_profile.name,
                        ggid: google_profile.googleId,
                        accessToken: response.accessToken
                    };
                    handleLogin(_data);
                }
            }
        }
    return(
        <>
        <h2 className="text-center">Welcome<br></br>to Birdy</h2>
        <Form className="form-content" method="post" onSubmit={onSubmit}>
          <div id="error-message" className="form-group alert alert-danger" style={{display: 'none'}}></div>
          <div className="form-group">
            <input
              name="FirstName"
              type="text"
              className="form-control"
              id="firstName"
              placeholder="First name *"
              required
            />
          </div>
          <div className="form-group">
            <input
              name="Surname"
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Last name *"
              required
            />
          </div>
          <div className="form-group">
            <input
              name="Phone"
              type="text"
              className="form-control"
              id="mobile"
              placeholder="Mobile number *"
              required
            />
          </div>
          <div className="form-group">
            <input
              name="Email"
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              required
            />
          </div>
          <div className="form-group">
            <input
              minLength="6"
              name="Password"
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
            />
          </div>
          <div className="text-center py-3">
            <div className="custom-control custom-checkbox remember-checkbox">
              <input
                name="Remember"
                type="checkbox"
                className="custom-control-input"
                id="rememberMe"
              />
              <label className="custom-control-label" htmlFor="rememberMe">
                Remember my email address when I next login
              </label>
            </div>
          </div>
          <div className="modal-button-wrap">
            <button
              type="submit"
              className="btn btn-black btn-w210 btn-lg">
              CREATE
            </button>
            <span>OR</span>
          </div>

          <GoogleLogin
                clientId={config.google_client_id}
                render={renderProps => (
                    <a href={`#`} onClick={renderProps.onClick} className={`btn btn-block btn-lg btn-google`} disabled={renderProps.disabled}>Continue with Google</a>
                )}
                buttonText="Continue with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                className="btn btn-block btn-lg btn-google"
                icon={false}
              />
              <FacebookLogin
                appId={config.fb_app_id}
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                cssClass="btn btn-block btn-lg btn-fb"
                icon=""
                textButton="Continue with Facebook"
              />
          {/* <a href="#" className="btn btn-block btn-lg btn-apple">Continue with Apple</a> */}

          <div className="text-center">
            <a href="/login" className="already-member">
              Already a member? <span>Log in</span>
            </a>
          </div>
        </Form>   
        </>
    )
}

export default RegisterModal
